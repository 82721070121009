import Rellax from 'rellax';

(function () {
  new Rellax('.intro__shape_1', {verticalSpeed: -2});
  new Rellax('.intro__shape_2', {verticalScrollAxis: 'x', horizontalSpeed: -2, horizontal: true});
  new Rellax('.intro__shape_3', {verticalSpeed: -4});
  new Rellax('.intro__shape_4', {verticalScrollAxis: 'x', horizontalSpeed: -1.5, horizontal: true});
  new Rellax('.intro__shape_5', {verticalScrollAxis: 'x', horizontalSpeed: 2, horizontal: true});
  new Rellax('.intro__shape_6', {verticalScrollAxis: 'x', horizontalSpeed: 3, horizontal: true});
  new Rellax('.intro__shape_7', {verticalSpeed: -0.5});

  document.querySelectorAll('.faq__title').forEach((elem) => {
    elem.addEventListener('click', function () {
      this.parentNode.parentNode.classList.toggle('faq__single--open');
    });
  });

  document.querySelectorAll('.intro__shape').forEach((elem) => {
    elem.classList.remove('intro__shape--hide');
  });

  document.querySelectorAll('a[href="#join"]').forEach((elem) => {
    elem.addEventListener('click', function (e) {
      e.preventDefault();
      const target = document.getElementById('join');
      const offset = (window.pageYOffset || document.documentElement.scrollTop);
      const scrollTo = target.getBoundingClientRect().top + offset - (window.innerHeight - target.clientHeight) / 2;
      window.scrollTo(0, scrollTo);
    });
  });

  document.querySelectorAll('.how-it-works__play').forEach((elem) => {
    elem.addEventListener('click', function (e) {
      elem.remove();
      document.querySelector('video').setAttribute('controls', 'controls');
      document.querySelector('video').play();
    });
  });
})();
